/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
	'use strict';

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function() {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

	//hamburger menu
	$("a[href='#menuExpand']").click(function(e) {
		$(".nav").toggleClass("menuOpen");
		e.preventDefault();
	});

	var headerHeight = $(window).height() - $(".nav-main").outerHeight() - 100;
	$("#header-slider-home").css('height', headerHeight);

	$( window ).resize(function() {
		$("#header-slider-home").css('height', headerHeight);
	});


	// add submenu button
	$('header .menu-item-has-children>a').after( "<i class=\"fa fa-chevron-circle-down\" aria-hidden=\"true\"></i>" );
	$('header .menu-item-has-children i').click(function () {
		var secondarySubmenu = $(this).next('ul');
		secondarySubmenu.toggleClass('show-submenu');
		$(this).toggleClass('flip');

		if (secondarySubmenu.find('.show-submenu').length > 0) {
			secondarySubmenu.find('i').removeClass('flip');
			secondarySubmenu.find('.show-submenu').removeClass('show-submenu');
		}
	});

	//  $("a[href=#subMenuExpand]").click(function(e) {
	//     $(".sub-menu").toggleClass("show");
	//     e.preventDefault();
	// });

	// MMBC Depot map
	$('.wpgmza_sl_main_div input[type="button"]').addClass('btn btn-primary');
	$('.wpgmza_basic_row').hide();
	$('.wpgmza_sl_radius_innerdiv2').append('<i class="fa fa-caret-down" aria-hidden="true"></i>');

})(jQuery);
